import { ProfileDto } from './account.dto'

export interface VendorInfoDto {
  service: {
    name: string
    id: string
  }
  code: string
  contractPeriod: string
  contractPeriodFrom: string
  contractPeriodTo: string
  contractPeriodUrl: string
  email: string
  gradeTier: string
  id: string
  name: string
  proofOfLicense: string
  proofOfLicenseUrl: string
  serviceId: string
  status: 'pending' | 'active'
  uenNo: string
  avatarUrl?: string
  profile: ProfileDto
}

export interface ListVendorInfoResponseDto {
  items: VendorInfoDto[]

  count: number
}

export interface NewVendorRequestBodyDto {
  name: string
  email?: string
  password: string
  contact: string
  serviceId: string
  uenNo: string
  proofOfLicense: string
  proofIsTemporary?: boolean
  gradeTier: string
  contractPeriod: string
  avatarId?: string
  avatarIsTemporary?: boolean
  countryCode: string
  code: string
}

export interface ImportExcelVendorResponseDto {
  added: number
  listSuccess: any[]
  listFail: any[]
}

export enum GradeTierOptions {
  A = 'Grade A',
  B = 'Grade B',
  C = 'Grade C',
  D = 'Grade D',
  E = 'Grade E'
}
