import { FilterQuery } from './_.dto'
export interface ServiceCategoryDto {
  id: string
  name: string
  icon: string
  iconUrl: string
  isOnDemand: boolean
  parentId?: string
  level: CategoryLevel
  parent: ServiceCategoryDto
}

export interface ListServiceCategoryResponseDto {
  items: ServiceCategoryDto[]
  count: number
}

export interface ServiceNoteDto {
  id?: string
  title: string
  description: string
  isFocus?: boolean
}

export interface ServiceDto {
  id: string
  name: string
  price: number
  priceRecurring?: number
  priceRecurring3Sessions?: number
  priceRecurring4Sessions?: number
  description: string
  isOnDemand?: boolean

  serviceBanner: string
  serviceBannerUrl: string

  secondBanner: string
  secondBannerUrl: string

  notes?: ServiceNoteDto[]
  categoryId: string
  category: ServiceCategoryDto
  serviceStatus: ServiceStatus
}

export interface ListServiceResponseDto {
  items: ServiceDto[]
  count: number
}

export interface CreateNewServiceCategoryRequestBodyDto {
  name: string
  icon: string
  isOnDemand: boolean
}

export interface UpdateServiceCategoryRequestBodyDto {
  name: string
  icon: string
  iconIsTemporary?: boolean
  isOnDemand: boolean
}

export interface CreateNewServiceRequestBodyDto {
  name: string
  price: number
  priceRecurring?: number
  description: string
  serviceBanner: string
  secondBanner: string
  notes?: ServiceNoteDto[]
  categoryId?: string
  isOnDemand?: boolean
  // serviceStatus: ServiceStatus
  priceRecurring3Sessions?: number
  priceRecurring4Sessions?: number
}

export interface UpdateServiceRequestBodyDto {
  name: string
  price: number
  priceRecurring?: number
  description: string
  serviceBanner: string
  secondBanner: string
  notes?: ServiceNoteDto[]
  categoryId: string
  isOnDemand?: boolean
  serviceStatus?: ServiceStatus
  priceRecurring3Sessions?: number
  priceRecurring4Sessions?: number
}

export enum CategoryLevel {
  Main = 'Main',
  Sub = 'Sub',
  Child = 'Child'
}

export enum CategoryLevelLabel {
  Main = 'Main Category',
  Sub = 'Sub Category',
  Child = 'Child Category'
}

export enum ServiceStatus {
  Published = 'Published',
  Unpublished = 'Unpublished'
}

export interface MultiSericeDto {
  serviceName?: string
  serviceId?: string
  mainCategoryId?: string
  subCategoryId?: string
  childCategoryId?: string
}

export interface ServicesFilterQuery extends FilterQuery {
  price?: string
}

export interface ImportExcelServiceResponseDto {
  added: number
  listSuccess: any[]
  listFail: any[]
}
